import React, { useState, useEffect } from 'react';

import { format } from 'date-fns';
import { NotificationContainer } from 'react-notifications';
import api from '../../services/api';
import AlbumManagerContext from './AlbumManagerContext';

import NavBar from '../../components/NavBar';
import Loader from '../../components/Loader';
import Error from '../../components/Error';
import Line from '../../components/Line';
import NavTabsGeneral from '../../components/NavTabsGeneral';

import TabInfo from './Tabs/Info';
import TabImage from './Tabs/Image';
import TabSong from './Tabs/Song';

import * as S from './styles';

const AlbumManager = props => {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [isContent, setContent] = useState(false);
  const [isNewData, setNewData] = useState(false);
  const [objData, setData] = useState({});

  const auxFormatData = data => {
    data.id = data.albId;
    switch (data.musStatus) {
      case 0:
        data._musStatus = 'Active';
        break;
      case 1:
        data._musStatus = 'Processing';
        break;
      case 2:
        data._musStatus = 'Disabled';
        break;
      case 3:
        data._musStatus = 'Blocked';
        break;
      default:
        data._musStatus = 'Disabled';
        break;
    }
    switch (data.musFeaturedMain) {
      case 0:
        data._musFeaturedMain = 'Active';
        break;
      case 1:
        data._musFeaturedMain = 'Disabled';
        break;
      default:
        data._musFeaturedMain = 'Disabled';
        break;
    }
    switch (data.musFeatured) {
      case 0:
        data._musFeatured = 'Active';
        break;
      case 1:
        data._musFeatured = 'Disabled';
        break;
      default:
        data._musFeatured = 'Disabled';
        break;
    }
    data._musGenre = '';
    data.GENRE.map((genre) => {
      data._musGenre += ` | ${genre.genName}`;
    });
    data._musGenre = data._musGenre.substr(2);
    data._musArtist = '';
    data.ARTIST.map((artist) => {
      data._musArtist += ` | ${artist.artName}`;
    });
    data._musArtist = data._musArtist.substr(2);
    switch (data.musExplicitLyrics) {
      case 0:
        data._musExplicitLyrics = 'Yes';
        break;
      case 1:
        data._musExplicitLyrics = 'Not';
        break;
      default:
        data._musExplicitLyrics = 'Not';
        break;
    }
    data._musDateCreated = format(new Date(data.musDateCreated), 'MM/dd/yyyy');
    data._musDateUpdated = format(new Date(data.musDateUpdated), 'MM/dd/yyyy');
    return data;
  };

  useEffect(() => {
    async function loadData() {
      const {
        match: {
          params: { id },
        },
      } = props;

      if (id === 'new') {
        // New Data
        setNewData(true);
        viewContent();
      } else {
        const apiGetData = api('GET', `/album/${id}`, null);

        const resGetData = await apiGetData;
        const {
          data: { resData },
        } = resGetData;
        console.log(resData);
        // console.log(auxFormatData(resData));
        setData(auxFormatData(resData));
        viewContent();
      }
    }
    try {
      loadData();
    } catch (error) {
      viewError();
    }
  }, []);

  const viewData = () => {
    return (
      <S.DivData>
        <NavTabsGeneral
          propContext={objData}
          propTabs={[
            {
              title: 'Info',
              component: <TabInfo propData={objData} propNewData={isNewData} />,
            },
            !isNewData && {
              title: 'Images',
              component: <TabImage propData={objData} />,
            },
            !isNewData && {
              title: 'Songs',
              component: <TabSong propData={objData} />,
            },
          ]}
        />
      </S.DivData>
    );
  };

  const viewLoading = status => {
    if (status === undefined) {
      console.log('type A');
      setContent(false);
      setError(false);
      setLoading(true);
    } else {
      console.log('type B');
      setLoading(status);
    }
  };

  const viewError = () => {
    setContent(false);
    setLoading(false);
    setError(true);
  };

  const viewContent = () => {
    setLoading(false);
    setError(false);
    setContent(true);
  };

  return (
    <>
      <NavBar />
      <S.Container>
        <S.ContainerHeader>
          <S.Title>Album</S.Title>
          <Line />
        </S.ContainerHeader>
        <S.ContainerContent>
          <AlbumManagerContext.Provider
            value={{ viewLoading, viewError, viewContent }}
          >
            {isLoading && <Loader />}
            {isError && <Error />}
            {isContent && viewData()}
            {/* {isLoading ? <Loader /> : viewData()} */}
          </AlbumManagerContext.Provider>
        </S.ContainerContent>
      </S.Container>
      <NotificationContainer />
    </>
  );
};

export default AlbumManager;
