import styled from 'styled-components';
import media from "styled-query";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  background-color: white;
  border: 2px solid #3079ff; //#00ffaa
  border-radius: 10px;

  justify-content: space-evenly;
  background: #053877;

  h3 {
    color: #3079ff;
  }

  h1 {
    color: #00ffaa;
  }

  ${media.lessThan("small")`
    padding: 15px;
  `}
`;
