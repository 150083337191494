import React from 'react';

import { Link } from 'react-router-dom';

import imgLogo from '../../assets/images/logo.png';

import * as S from './styles';

const NotFound = () => (
  <S.Container>
    <S.DivWrapper>
      <S.Img src={imgLogo} alt="Logo" />
      <S.H1>Page Not Found</S.H1>
      <S.DivButton>
        <Link to="/">
          <S.Button>GO HOME</S.Button>
        </Link>
      </S.DivButton>
    </S.DivWrapper>
  </S.Container>
);

export default NotFound;
