import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  NotificationContainer,
  NotificationManager as N,
} from 'react-notifications';

import { useHistory } from 'react-router-dom';

import api from '../../../../services/api';
import LiveManagerContext from '../../LiveManagerContext';

import ButtonLoader from '../../../../components/ButtonLoader';
import RatingView from '../../../../components/RatingView';

import 'react-notifications/lib/notifications.css';
import * as S from './styles';

const Info = ({ propData, propNewData }) => {
  const history = useHistory();
  const { viewLoading, viewError, viewContent } = useContext(
    LiveManagerContext
  );

  const [objData, setData] = useState(propData);
  const [optCategory, setCategory] = useState([
    propNewData
      ? { id: 'undefined', title: 'undefined' }
      : { id: objData.CATEGORY[0].id, title: objData.CATEGORY[0].name },
  ]);
  const [mount, setMount] = useState(false);
  // console.log(objData);

  const optStatus = [
    { id: 'Active', title: 'Active' },
    { id: 'Processing', title: 'Processing' },
    { id: 'Disabled', title: 'Disabled' },
    { id: 'Blocked', title: 'Blocked' },
  ];
  const optStatusSimple = [
    { id: 'Active', title: 'Active' },
    { id: 'Disabled', title: 'Disabled' },
  ];
  const optType = [
    { id: 'Movie', title: 'Movie' },
    { id: 'TV Show', title: 'TV Show' },
  ]; // kes-dev-prod
  const optMPAA = [
    { id: 'G', title: 'G' },
    { id: 'PG', title: 'PG' },
    { id: 'PG-13', title: 'PG-13' },
    { id: 'R', title: 'R' },
    { id: 'NC-17', title: 'NC-17' },
  ];

  const auxFormatDataCategory = data => {
    return { id: data.catId, title: data.catName };
  };

  useEffect(() => {
    async function loadData() {
      // kes-dev-tmp
      const lstPromises = [api('GET', `/category`, null)];

      const resGetData = await Promise.all(lstPromises);

      setCategory(resGetData[0].data.resData.map(auxFormatDataCategory));
      setMount(true);

      viewContent();
    }
    try {
      loadData();
    } catch (error) {
      viewError();
    }
  }, []);

  const handleSubmit = async data => {
    try {
      if (handleValidate()) {
        if (propNewData) {
          viewLoading();
          console.log(`Data POST`, data);
          const resPostData = await api('POST', `/live`, data);
          console.log(resPostData);

          N.success('Successfully updated');

          setTimeout(() => {
            history.push(`/live/${resPostData.data.resData.livLanguage}`);
            window.location.reload();
          }, 1500);
        } else {
          viewLoading();
          console.log(`Data PUT`, data);
          const resPutData = await api(
            'PUT',
            `/live/${objData.livLanguage}/info`,
            data
          );
          console.log(resPutData);

          N.success('Successfully updated');

          setTimeout(() => {
            // history.push('/');
            window.location.reload();
          }, 1500);
        }
      }

      function handleValidate() {
        let varControl = true;

        console.log(data);

        if (
          data._livStatus !== 'Active' &&
          data._livStatus !== 'Processing' &&
          data._livStatus !== 'Disabled' &&
          data._livStatus !== 'Blocked'
        ) {
          varControl = false;
          N.error('Status', 'Check parameters');
        } else {
          if (
            propNewData ||
            (objData.livStatus !== 1 && objData.livStatus !== 3)
          ) {
            if (
              data._livStatus !== 'Active' &&
              data._livStatus !== 'Disabled'
            ) {
              varControl = false;
              N.error('Status', 'Check parameters');
            }
          }
          switch (data._livStatus) {
            case 'Active':
              data.livStatus = 0;
              break;
            case 'Processing':
              data.livStatus = 1;
              break;
            case 'Disabled':
              data.livStatus = 2;
              break;
            case 'Blocked':
              data.livStatus = 3;
              break;
            default:
              varControl = false;
              N.error('Error Status', 'Check parameters');
              break;
          }
        }

        if (data.livCategory === '' || data.livCategory === ' ') {
          varControl = false;
          N.error('Category', 'Check parameters');
        } else {
          const category1 = data.livCategory;
          data.livCategory = [];
          data.livCategory.push(category1);
        }

        if (data.livTitle === '') {
          varControl = false;
          N.error('Title', 'Check parameters');
        }

        if (data.livDescription === '') {
          varControl = false;
          N.error('Description', 'Check parameters');
        }

        if (data.livMPAA === '') {
          varControl = false;
          N.error('MPAA', 'Check parameters');
        }

        if (data.livLinkStreamHlsAds === '') {
          varControl = false;
          N.error('Link Hls', 'Check parameters');
        }

        if (data.livLinkStreamHlsFull === '') {
          varControl = false;
          N.error('Link Dash', 'Check parameters');
        }

        return varControl;
      }
    } catch (error) {
      console.error(error);
      N.error('Error save');
      viewContent();
    }
  };

  return (
    <>
      <S.Form onSubmit={handleSubmit} initialData={objData}>
        <S.DivInput propW="25%">
          <small>Status</small>
          <S.Select
            name="_livStatus"
            options={
              propNewData ||
              (objData.livStatus !== 1 && objData.livStatus !== 3)
                ? optStatusSimple
                : optStatus
            }
            disabled={objData.livStatus === 1 || objData.livStatus === 3}
          />
        </S.DivInput>
        <S.DivInput propW="25%">
          <small>Date Created</small>
          <S.Input name="_livDateCreated" disabled />
        </S.DivInput>
        <S.DivInput propW="25%">
          <small>Date Updated</small>
          <S.Input name="_livDateUpdated" disabled />
        </S.DivInput>
        <S.DivInput propW="25%">
          <small>Rating</small>
          <RatingView propValue={3.5} />
          {/* <S.Input name="livRating" disabled /> */}
        </S.DivInput>
        <S.DivInput propW="25%">
          <small>Category</small>
          <S.Select name="livCategory" options={optCategory} disabled={false} />
        </S.DivInput>
        <S.DivInput propW="75%">
          <small>Title</small>
          <S.Input name="livTitle" disabled={false} />
        </S.DivInput>
        <S.DivInput propW="100%">
          <small>Description</small>
          <S.Input name="livDescription" disabled={false} />
        </S.DivInput>
        <S.DivInput propW="25%">
          <small>Language</small>
          <S.Input name="livLanguage" disabled />
        </S.DivInput>
        <S.DivInput propW="50%">
          <small>Source Locations</small>
          <S.Input name="livSourceLocations" disabled />
        </S.DivInput>
        <S.DivInput propW="25%">
          <small>MPAA</small>
          <S.Select name="livMPAA" options={optMPAA} disabled={false} />
        </S.DivInput>
        <S.DivInput propW="50%">
          <small>Link HLS Ads</small>
          <S.Input name="livLinkStreamHlsAds" disabled={false} />
        </S.DivInput>
        <S.DivInput propW="50%">
          <small>Link HLS Full</small>
          <S.Input name="livLinkStreamHlsFull" disabled={false} />
        </S.DivInput>
        <S.DivButton>
          <ButtonLoader propTitle="SAVE" propSpinner={false} />
        </S.DivButton>
      </S.Form>
      <NotificationContainer />
    </>
  );
};

Info.defaultProps = {
  propData: {},
  propNewData: false,
};

Info.propTypes = {
  propData: PropTypes.object,
  propNewData: PropTypes.bool,
};

export default Info;
