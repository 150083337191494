import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  NotificationContainer,
  NotificationManager as N,
} from 'react-notifications';

import { useHistory } from 'react-router-dom';

import api from '../../../../services/api';
import AlbumManagerContext from '../../AlbumManagerContext';

import ButtonLoader from '../../../../components/ButtonLoader';
import RatingView from '../../../../components/RatingView';

import 'react-notifications/lib/notifications.css';
import * as S from './styles';

const Info = ({ propData, propNewData }) => {
  const history = useHistory();
  const { viewLoading, viewError, viewContent } = useContext(
    AlbumManagerContext
  );

  const [objData, setData] = useState(propData);
  const [optLabel, setLabel] = useState([
    propNewData
      ? { id: 'undefined', title: 'undefined' }
      : { id: objData.LABEL.labId, title: objData.LABEL.labName },
  ]);
  const [optGenre, setGenre] = useState([
    propNewData
      ? { id: 'undefined', title: 'undefined' }
      : { id: 'undefined', title: 'undefined' },
  ]);
  const [optArtist, setArtist] = useState([
    propNewData
      ? { id: 'undefined', title: 'undefined' }
      : { id: 'undefined', title: 'undefined' },
  ]);
  const [mount, setMount] = useState(false);
  // console.log(objData);

  const optStatus = [
    { id: 'Active', title: 'Active' },
    { id: 'Processing', title: 'Processing' },
    { id: 'Disabled', title: 'Disabled' },
    { id: 'Blocked', title: 'Blocked' },
  ];
  const optStatusSimple = [
    { id: 'Active', title: 'Active' },
    { id: 'Disabled', title: 'Disabled' },
  ];
  const optFeatured = [
    { id: 'Active', title: 'Active' },
    { id: 'Disabled', title: 'Disabled' },
  ];
  const optContentLicense = [
    { id: 'free', title: 'free' },
    { id: 'plus', title: 'plus' },
  ];
  const optYearReleased = [
    { id: '2024', title: '2024' },
    { id: '2023', title: '2023' },
    { id: '2022', title: '2022' },
    { id: '2021', title: '2021' },
    { id: '2020', title: '2020' },
    { id: '2019', title: '2019' },
    { id: '2018', title: '2018' },
    { id: '2017', title: '2017' },
    { id: '2016', title: '2016' },
    { id: '2015', title: '2015' },
    { id: '2014', title: '2014' },
    { id: '2013', title: '2013' },
    { id: '2012', title: '2012' },
    { id: '2011', title: '2011' },
    { id: '2010', title: '2010' },
    { id: '2009', title: '2009' },
    { id: '2008', title: '2008' },
    { id: '2007', title: '2007' },
    { id: '2006', title: '2006' },
    { id: '2005', title: '2005' },
    { id: '2004', title: '2004' },
    { id: '2003', title: '2003' },
    { id: '2002', title: '2002' },
    { id: '2001', title: '2001' },
    { id: '2000', title: '2000' },
    { id: '1999', title: '1999' },
    { id: '1998', title: '1998' },
    { id: '1997', title: '1997' },
    { id: '1996', title: '1996' },
    { id: '1995', title: '1995' },
    { id: '1994', title: '1994' },
    { id: '1993', title: '1993' },
    { id: '1992', title: '1992' },
    { id: '1991', title: '1991' },
    { id: '1990', title: '1990' },
    { id: '1989', title: '1989' },
    { id: '1988', title: '1988' },
    { id: '1987', title: '1987' },
    { id: '1986', title: '1986' },
    { id: '1985', title: '1985' },
    { id: '1984', title: '1984' },
    { id: '1983', title: '1983' },
    { id: '1982', title: '1982' },
    { id: '1981', title: '1981' },
    { id: '1980', title: '1980' },
    { id: '1979', title: '1979' },
    { id: '1978', title: '1978' },
    { id: '1977', title: '1977' },
    { id: '1976', title: '1976' },
    { id: '1975', title: '1975' },
    { id: '1974', title: '1974' },
    { id: '1973', title: '1973' },
    { id: '1972', title: '1972' },
    { id: '1971', title: '1971' },
    { id: '1970', title: '1970' },
    { id: '1969', title: '1969' },
    { id: '1968', title: '1968' },
    { id: '1967', title: '1967' },
    { id: '1966', title: '1966' },
    { id: '1965', title: '1965' },
    { id: '1964', title: '1964' },
    { id: '1963', title: '1963' },
    { id: '1962', title: '1962' },
    { id: '1961', title: '1961' },
    { id: '1960', title: '1960' },
    { id: '1959', title: '1959' },
    { id: '1958', title: '1958' },
    { id: '1957', title: '1957' },
    { id: '1956', title: '1956' },
    { id: '1955', title: '1955' },
    { id: '1954', title: '1954' },
    { id: '1953', title: '1953' },
    { id: '1952', title: '1952' },
    { id: '1951', title: '1951' },
    { id: '1950', title: '1950' },
  ];
  const optCollection = [
    { id: 'Single', title: 'Single' },
    { id: 'Album', title: 'Album' },
  ];
  const optMPAA = [
    { id: 'G', title: 'G' },
    { id: 'PG', title: 'PG' },
    { id: 'PG-13', title: 'PG-13' },
    { id: 'R', title: 'R' },
    { id: 'NC-17', title: 'NC-17' },
  ];
  const optExplicitLyrics = [
    { id: 'Yes', title: 'Yes' },
    { id: 'Not', title: 'Not' },
  ];
  const optSale = [
    { id: 'Not', title: 'Not' },
    { id: 'Yes', title: 'Yes' },
  ];
  const optSalePrice = [
    { id: '0.00', title: '0.00' },
    { id: '0.99', title: '0.99' },
    { id: '1.99', title: '1.99' },
    { id: '6.99', title: '6.99' },
    { id: '12.99', title: '12.99' },
    { id: '15.99', title: '15.99' },
    { id: '19.99', title: '19.99' },
  ];

  const auxFormatDataLabel = data => {
    return { id: data.labId, title: data.labName };
  };
  const auxFormatDataGenre = data => {
    return { id: data.genId, title: data.genName };
  };
  const auxFormatDataArtist = data => {
    return { id: data.artId, title: data.artName };
  };

  useEffect(() => {
    async function loadData() {
      // kes-dev-tmp
      const lstPromises = [
        api('GET', `/label`, null),
        api('GET', `/genre`, null),
        api('GET', `/artist`, null),
      ];

      const resGetData = await Promise.all(lstPromises);

      setLabel(resGetData[0].data.resData.map(auxFormatDataLabel));
      setGenre(resGetData[1].data.resData.map(auxFormatDataGenre));
      setArtist(resGetData[2].data.resData.map(auxFormatDataArtist));
      setMount(true);

      viewContent();
    }
    try {
      loadData();
    } catch (error) {
      viewError();
    }
  }, []);

  const handleSubmit = async data => {
    try {
      if (handleValidate()) {
        if (propNewData) {
          viewLoading();
          console.log(`Data POST`, data);
          const resPostData = await api('POST', `/album`, data);
          console.log(resPostData);

          N.success('Successfully updated');

          setTimeout(() => {
            history.push(`/album/${resPostData.data.resData.albId}`);
            window.location.reload();
          }, 1500);
        } else {
          viewLoading();
          console.log(`Data PUT`, data);
          const resPutData = await api(
            'PUT',
            `/album/${objData.albId}/info`,
            data
          );
          console.log(resPutData);

          N.success('Successfully updated');

          setTimeout(() => {
            // history.push('/');
            window.location.reload();
          }, 1500);
        }
      }

      function handleValidate() {
        let varControl = true;

        console.log(data);

        if (
          data._musStatus !== 'Active' &&
          data._musStatus !== 'Processing' &&
          data._musStatus !== 'Disabled' &&
          data._musStatus !== 'Blocked'
        ) {
          varControl = false;
          N.error('Status', 'Check parameters');
        } else {
          if (
            propNewData ||
            (objData.musStatus !== 1 && objData.musStatus !== 3)
          ) {
            if (
              data._musStatus !== 'Active' &&
              data._musStatus !== 'Disabled'
            ) {
              varControl = false;
              N.error('Status', 'Check parameters');
            }
          }
          switch (data._musStatus) {
            case 'Active':
              data.musStatus = 0;
              break;
            case 'Processing':
              data.musStatus = 1;
              break;
            case 'Disabled':
              data.musStatus = 2;
              break;
            case 'Blocked':
              data.musStatus = 3;
              break;
            default:
              varControl = false;
              N.error('Error Status', 'Check parameters');
              break;
          }
        }

        if (data.labId === '') {
          varControl = false;
          N.error('Label', 'Check parameters');
        }

        if (data.musCollection === '') {
          varControl = false;
          N.error('Collection', 'Check parameters');
        }

        if (
          data._musFeaturedMain !== 'Active' &&
          data._musFeaturedMain !== 'Disabled'
        ) {
          varControl = false;
          N.error('Featured Main', 'Check parameters');
        } else {
          switch (data._musFeaturedMain) {
            case 'Active':
              data.musFeaturedMain = 0;
              break;
            case 'Disabled':
              data.musFeaturedMain = 1;
              break;
            default:
              varControl = false;
              N.error('Featured Main', 'Check parameters');
              break;
          }
        }

        if (
          data._musFeatured !== 'Active' &&
          data._musFeatured !== 'Disabled'
        ) {
          varControl = false;
          N.error('Featured', 'Check parameters');
        } else {
          switch (data._musFeatured) {
            case 'Active':
              data.musFeatured = 0;
              break;
            case 'Disabled':
              data.musFeatured = 1;
              break;
            default:
              varControl = false;
              N.error('Featured', 'Check parameters');
              break;
          }
        }

        if (data.musContentLicense === '') {
          varControl = false;
          N.error('Content License', 'Check parameters');
        }

        if (data.musName === '') {
          varControl = false;
          N.error('Name', 'Check parameters');
        }

        if (data.musDescription === '') {
          varControl = false;
          N.error('Description', 'Check parameters');
        }

        data.musYearReleased = parseInt(data.musYearReleased);

        const lstGenresTemp = [];
        if (data.musGenre1 === '' || data.musGenre1 === ' ') {
          varControl = false;
          N.error('Genre', 'Check parameters');
        } else {
          let tmpGenre = '';
          // 1
          tmpGenre = data.musGenre1;
          lstGenresTemp.push(tmpGenre);
          // 2
          if (data.musGenre2 !== '' && data.musGenre2 !== ' ') {
            tmpGenre = data.musGenre2;
            lstGenresTemp.push(tmpGenre);
          }
          // 3
          if (data.musGenre3 !== '' && data.musGenre3 !== ' ') {
            tmpGenre = data.musGenre3;
            lstGenresTemp.push(tmpGenre);
          }
        }
        data.musGenre = lstGenresTemp.filter(function(item, index) {
          return lstGenresTemp.indexOf(item) === index;
        });

        // if (
        //   data._musExplicitLyrics !== 'Yes' &&
        //   data._musExplicitLyrics !== 'Not'
        // ) {
        //   varControl = false;
        //   N.error('Explicit Lyrics', 'Check parameters');
        // } else {
        //   switch (data._musExplicitLyrics) {
        //     case 'Yes':
        //       data.musExplicitLyrics = 0;
        //       break;
        //     case 'Not':
        //       data.musExplicitLyrics = 1;
        //       break;
        //     default:
        //       varControl = false;
        //       N.error('Explicit Lyrics', 'Check parameters');
        //       break;
        //   }
        // }

        const lstArtistsTemp = [];
        if (data.musArtist1 === '' || data.musArtist1 === ' ') {
          varControl = false;
          N.error('Artist', 'Check parameters');
        } else {
          let tmpArtist = '';
          // 1
          tmpArtist = data.musArtist1;
          lstArtistsTemp.push(tmpArtist);
          // 2
          if (data.musArtist2 !== '' && data.musArtist2 !== ' ') {
            tmpArtist = data.musArtist2;
            lstArtistsTemp.push(tmpArtist);
          }
          // 3
          if (data.musArtist3 !== '' && data.musArtist3 !== ' ') {
            tmpArtist = data.musArtist3;
            lstArtistsTemp.push(tmpArtist);
          }
        }
        data.musArtist = lstArtistsTemp.filter(function(item, index) {
          return lstArtistsTemp.indexOf(item) === index;
        });

        return varControl;
      }
    } catch (error) {
      console.error(error);
      N.error('Error save');
      viewContent();
    }
  };

  if (mount) {
    return (
      <>
        <S.Form onSubmit={handleSubmit} initialData={objData}>
          <S.DivInput propW="25%">
            <small>Status</small>
            <S.Select
              name="_musStatus"
              options={
                propNewData ||
                (objData.musStatus !== 1 && objData.musStatus !== 3)
                  ? optStatusSimple
                  : optStatus
              }
              disabled={objData.musStatus === 1 || objData.musStatus === 3}
            />
          </S.DivInput>
          <S.DivInput propW="25%">
            <small>Date Created</small>
            <S.Input name="_musDateCreated" disabled />
          </S.DivInput>
          <S.DivInput propW="25%">
            <small>Date Updated</small>
            <S.Input name="_musDateUpdated" disabled />
          </S.DivInput>
          <S.DivInput propW="25%">
            <small>Popularity</small>
            <RatingView propValue={3.5} />
            {/* <S.Input name="musRating" disabled /> */}
          </S.DivInput>
          <S.DivInput propW="25%">
            <small>Label</small>
            <S.Select name="labId" options={optLabel} disabled={false} />
          </S.DivInput>
          <S.DivInput propW="25%">
            <small>Collection</small>
            <S.Select name="musCollection" options={optCollection} disabled={false} />
          </S.DivInput>
          <S.DivInput propW="25%">
            <small>Featured Main</small>
            <S.Select
              name="_musFeaturedMain"
              options={optFeatured}
              disabled={false}
            />
          </S.DivInput>
          <S.DivInput propW="25%">
            <small>Featured</small>
            <S.Select
              name="_musFeatured"
              options={optFeatured}
              disabled={false}
            />
          </S.DivInput>
          <S.DivInput propW="25%">
            <small>Content License</small>
            <S.Select
              name="musContentLicense"
              options={optContentLicense}
              disabled={false}
            />
          </S.DivInput>
          <S.DivInput propW="25%">
            <small>Name</small>
            <S.Input name="musName" disabled={false} />
          </S.DivInput>
          <S.DivInput propW="50%">
            <small>Description</small>
            <S.Input name="musDescription" disabled={false} />
          </S.DivInput>
          <S.DivInput propW="25%">
            <small>Year Released</small>
            <S.Select name="musYearReleased" options={optYearReleased} disabled={false} />
          </S.DivInput>
          <S.DivInput propW="75%">
            <small>Genres</small>
            <S.Input name="_musGenre" disabled={true} />
          </S.DivInput>
          <S.DivInput propW="25%">
            <small>Explicit Lyrics</small>
            <S.Select name="_musExplicitLyrics" options={optExplicitLyrics} disabled={true} />
          </S.DivInput>
          <S.DivInput propW="75%">
            <small>Artists</small>
            <S.Input name="_musArtist" disabled={true} />
          </S.DivInput>
          <S.DivButton>
            <ButtonLoader propTitle="SAVE" propSpinner={false} />
          </S.DivButton>
        </S.Form>
        <NotificationContainer />
      </>
    );
  }
  return <></>;
};

Info.defaultProps = {
  propData: {},
  propNewData: false,
};

Info.propTypes = {
  propData: PropTypes.object,
  propNewData: PropTypes.bool,
};

export default Info;
