import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  NotificationContainer,
  NotificationManager as N,
} from 'react-notifications';

import { useHistory } from 'react-router-dom';

import api from '../../../../services/api';
import ArtistManagerContext from '../../ArtistManagerContext';

import ButtonLoader from '../../../../components/ButtonLoader';
import RatingView from '../../../../components/RatingView';

import 'react-notifications/lib/notifications.css';
import * as S from './styles';

const Info = ({ propData, propNewData, propNewDataId }) => {
  const history = useHistory();
  const { viewLoading, viewError, viewContent } = useContext(
    ArtistManagerContext
  );

  const [objData, setData] = useState(propData);
  // console.log(objData);

  const optFeatured = [
    { id: 'Active', title: 'Active' },
    { id: 'Disabled', title: 'Disabled' },
  ];
  const optStatus = [
    { id: 'Active', title: 'Active' },
    // { id: 'Processing', title: 'Processing' },
    { id: 'Disabled', title: 'Disabled' },
    // { id: 'Blocked', title: 'Blocked' },
  ];

  useEffect(() => {
    async function loadData() {
      viewContent();
    }
    try {
      loadData();
    } catch (error) {
      viewError();
    }
  }, []);

  const handleSubmit = async data => {
    try {
      if (handleValidate()) {
        if (propNewData) {
          viewLoading();
          // if (propNewDataId !== '') {
          //   data.artId = propNewDataId;
          // }
          data.artId = '';
          console.log(`Data POST`, data);
          const resPostData = await api('POST', `/artist`, data);
          console.log(resPostData);

          N.success('Successfully created');

          setTimeout(() => {
            history.push(`/artist/${resPostData.data.resData.artId}`);
            window.location.reload();
          }, 1500);
        } else {
          viewLoading();
          console.log(`Data PUT`, data);
          const resPutData = await api(
            'PUT',
            `/artist/${objData.artId}/info`,
            data
          );
          console.log(resPutData);

          N.success('Successfully updated');

          setTimeout(() => {
            // history.push('/');
            window.location.reload();
          }, 1500);
        }
      }

      function handleValidate() {
        let varControl = true;

        console.log(data);
        if (data._artStatus !== 'Active' && data._artStatus !== 'Disabled') {
          varControl = false;
          N.error('Status', 'Check parameters');
        } else {
          switch (data._artStatus) {
            case 'Active':
              data.artStatus = 0;
              break;
            case 'Disabled':
              data.artStatus = 2;
              break;
            default:
              varControl = false;
              N.error('Error Status', 'Check parameters');
              break;
          }
        }

        if (data.artName === '') {
          varControl = false;
          N.error('Name', 'Check parameters');
        }

        if (data._artFeaturedMain !== 'Active' && data._artFeaturedMain !== 'Disabled') {
          varControl = false;
          N.error('Featured Main', 'Check parameters');
        } else {
          switch (data._artFeaturedMain) {
            case 'Active':
              data.artFeaturedMain = 0;
              break;
            case 'Disabled':
              data.artFeaturedMain = 1;
              break;
            default:
              varControl = false;
              N.error('Featured Main', 'Check parameters');
              break;
          }
        }

        if (data._artFeatured !== 'Active' && data._artFeatured !== 'Disabled') {
          varControl = false;
          N.error('Featured', 'Check parameters');
        } else {
          switch (data._artFeatured) {
            case 'Active':
              data.artFeatured = 0;
              break;
            case 'Disabled':
              data.artFeatured = 1;
              break;
            default:
              varControl = false;
              N.error('Featured', 'Check parameters');
              break;
          }
        }

        return varControl;
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 409) {
        N.error(error.response.data.resMessage);
        viewContent();
      } else {
        N.error('Error save');
        viewContent();
      }
    }
  };

  return (
    <>
      <S.Form onSubmit={handleSubmit} initialData={objData}>
        <S.DivInput propW="34%">
          <small>Status</small>
          <S.Select name="_artStatus" options={optStatus} disabled={false} />
        </S.DivInput>
        <S.DivInput propW="33%">
          <small>Date Created</small>
          <S.Input name="_artDateCreated" disabled />
        </S.DivInput>
        <S.DivInput propW="33%">
          <small>Date Updated</small>
          <S.Input name="_artDateUpdated" disabled />
        </S.DivInput>
        <S.DivInput propW="100%">
          <small>Name</small>
          <S.Input name="artName" disabled={false} />
        </S.DivInput>
        <S.DivInput propW="100%">
          <small>Description</small>
          <S.Input name="artDescription" disabled={false} />
        </S.DivInput>
        <S.DivInput propW="50%">
          <small>Featured Main</small>
          <S.Select
            name="_artFeaturedMain"
            options={optFeatured}
            disabled={false}
          />
        </S.DivInput>
        <S.DivInput propW="50%">
          <small>Featured</small>
          <S.Select
            name="_artFeatured"
            options={optFeatured}
            disabled={false}
          />
        </S.DivInput>
        <S.DivButton>
          <ButtonLoader propTitle="SAVE" propSpinner={false} />
        </S.DivButton>
      </S.Form>
      <NotificationContainer />
    </>
  );
};

Info.defaultProps = {
  propData: {},
  propNewData: false,
  propNewDataId: '',
};

Info.propTypes = {
  propData: PropTypes.object,
  propNewData: PropTypes.bool,
  propNewDataId: PropTypes.string,
};

export default Info;
