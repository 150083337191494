import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import { format } from 'date-fns';
import ReactPlayer from 'react-player';
import Dropzone from 'react-dropzone';
import {
  NotificationContainer,
  NotificationManager as N,
} from 'react-notifications';

import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import config from '../../../../config/aws';
import api from '../../../../services/api';
import { fileUploadImg, fileUploadMedia } from '../../../../services/s3';
import AlbumManagerContext from '../../AlbumManagerContext';

import imgLoading from '../../../../assets/images/loading/icoLoading-2.svg';
import imgSuccess from '../../../../assets/images/loading/icoSuccess-1.svg';
import imgSearch from '../../../../assets/images/loading/icoSearch-1.svg';

import CustomLinearProgress from '../../../../components/CustomLinearProgress';
import Loader from '../../../../components/Loader';
import ButtonLoader from '../../../../components/ButtonLoader';
import RatingView from '../../../../components/RatingView';
import ButtonNew from '../../../../components/ButtonNew';
import ButtonAdd from '../../../../components/ButtonAdd';
import ButtonBack from '../../../../components/ButtonBack';
import BadgeArtist from '../../../../components/BadgeArtist';
import Table from '../../../../components/Table';
import { led } from '../../../../utils/led';
import { formatNumberTwoDigit } from '../../../../utils/format';
import 'react-notifications/lib/notifications.css';

import * as S from './styles';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  divDropzone: {
    borderRadius: '4px',
    padding: '30px',
    textAlign: 'center',
    border: '1px dashed #e4c1cb',
    cursor: 'pointer',
    color: '#e71a69',
    '& > p': {
      margin: 0,
      fontSize: '15px',
    },
    '&:hover': {
      border: '2px dashed #e71a69',
    },
    '&:disabled': {
      border: '2px dashed #b7b7b7',
      color: '#b7b7b7',
    },
  },
  divDropzoneDisabled: {
    borderRadius: '4px',
    padding: '30px',
    textAlign: 'center',
    cursor: 'progress',
    border: '2px dashed #b7b7b7',
    color: '#b7b7b7',
    '& > p': {
      margin: 0,
      fontSize: '18px',
    },
  },
  modalRoot: {
    overflow: 'auto',
    display: 'block',
  },
  modal: {
    maxWidth: '250px',
    margin: 'auto',
    borderRadius: '6px',
    overflow: 'visible',
    maxHeight: 'unset',
    width: '100%',
    marginTop: '130px !important',
  },
  modalHeader: {
    borderBottom: 'none',
    paddingTop: '24px',
    paddingRight: '24px',
    paddingBottom: '0',
    paddingLeft: '24px',
    minHeight: '16.43px',
  },
  modalTitle: {
    margin: '0',
    lineHeight: '1.5',
    textAlign: 'center',
    color: '#03163d',
  },
  modalBody: {
    paddingTop: '24px',
    paddingRight: '24px',
    paddingBottom: '16px',
    paddingLeft: '24px',
    position: 'relative',
    overflow: 'visible',
    textAlign: 'center',
  },
  imgProgress: {
    width: '75px',
  },
  imgProgressS: {
    width: '75px',
    padding: '15px',
  },
}));

const Song = ({ propData }) => {
  const history = useHistory();
  const classes = useStyles();

  const { viewLoading, viewError, viewContent } = useContext(
    AlbumManagerContext
  );
  // const [typeAlbum, setTypeAlbum] = useState('[undefined]');
  const [lstData, setDataTable] = useState([]);
  const [objDataMain, setDataMain] = useState(propData);
  const [isStatus, setStatus] = useState(false);
  const [isManagerData, setManagerData] = useState(false);
  const [isManagerDataNew, setManagerDataNew] = useState(false);
  const [lstSongArtist, setSongArtist] = useState([]);
  const [songLinkPlayer, setSongLinkPlayer] = useState(null);

  const [objSong, setSong] = useState({});
  const [objSongBkp, setSongBkp] = useState({});
  const [hlsLinkPlayer, setHlsLinkPlayer] = useState(null);

  const [isDropzone, setIsDropzone] = useState(true);

  const [upProgress, setUpProgress] = useState(0);
  const [modUpload, setModUpload] = useState(false);
  const [modArtist, setModArtist] = useState(false);

  const [objDefault, setDefault] = useState({
    musThumbnailSquare: 'https://cdn.album.pdtvpro.com/albumThumbnailLand.png',
  });
  const [objTemp, setTemp] = useState({});
  const [isSearch, setSearch] = useState(false);
  const player = React.createRef();

  const [optGenre, setGenre] = useState([
    isManagerDataNew
      ? { id: 'undefined', title: 'undefined' }
      : { id: 'undefined', title: 'undefined' },
  ]);
  const [optArtist, setArtist] = useState([
    isManagerDataNew
      ? { id: 'undefined', title: 'undefined' }
      : { id: 'undefined', title: 'undefined' },
  ]);
  const [mount, setMount] = useState(false);

  const optStatus = [
    { id: 'Active', title: 'Active' },
    { id: 'Processing', title: 'Processing' },
    { id: 'Disabled', title: 'Disabled' },
    { id: 'Blocked', title: 'Blocked' },
  ];
  const optStatusSimple = [
    { id: 'Active', title: 'Active' },
    { id: 'Disabled', title: 'Disabled' },
  ];
  const optFeatured = [
    { id: 'Active', title: 'Active' },
    { id: 'Disabled', title: 'Disabled' },
  ];
  const optYearReleased = [
    { id: '2024', title: '2024' },
    { id: '2023', title: '2023' },
    { id: '2022', title: '2022' },
    { id: '2021', title: '2021' },
    { id: '2020', title: '2020' },
    { id: '2019', title: '2019' },
    { id: '2018', title: '2018' },
    { id: '2017', title: '2017' },
    { id: '2016', title: '2016' },
    { id: '2015', title: '2015' },
    { id: '2014', title: '2014' },
    { id: '2013', title: '2013' },
    { id: '2012', title: '2012' },
    { id: '2011', title: '2011' },
    { id: '2010', title: '2010' },
    { id: '2009', title: '2009' },
    { id: '2008', title: '2008' },
    { id: '2007', title: '2007' },
    { id: '2006', title: '2006' },
    { id: '2005', title: '2005' },
    { id: '2004', title: '2004' },
    { id: '2003', title: '2003' },
    { id: '2002', title: '2002' },
    { id: '2001', title: '2001' },
    { id: '2000', title: '2000' },
    { id: '1999', title: '1999' },
    { id: '1998', title: '1998' },
    { id: '1997', title: '1997' },
    { id: '1996', title: '1996' },
    { id: '1995', title: '1995' },
    { id: '1994', title: '1994' },
    { id: '1993', title: '1993' },
    { id: '1992', title: '1992' },
    { id: '1991', title: '1991' },
    { id: '1990', title: '1990' },
    { id: '1989', title: '1989' },
    { id: '1988', title: '1988' },
    { id: '1987', title: '1987' },
    { id: '1986', title: '1986' },
    { id: '1985', title: '1985' },
    { id: '1984', title: '1984' },
    { id: '1983', title: '1983' },
    { id: '1982', title: '1982' },
    { id: '1981', title: '1981' },
    { id: '1980', title: '1980' },
    { id: '1979', title: '1979' },
    { id: '1978', title: '1978' },
    { id: '1977', title: '1977' },
    { id: '1976', title: '1976' },
    { id: '1975', title: '1975' },
    { id: '1974', title: '1974' },
    { id: '1973', title: '1973' },
    { id: '1972', title: '1972' },
    { id: '1971', title: '1971' },
    { id: '1970', title: '1970' },
    { id: '1969', title: '1969' },
    { id: '1968', title: '1968' },
    { id: '1967', title: '1967' },
    { id: '1966', title: '1966' },
    { id: '1965', title: '1965' },
    { id: '1964', title: '1964' },
    { id: '1963', title: '1963' },
    { id: '1962', title: '1962' },
    { id: '1961', title: '1961' },
    { id: '1960', title: '1960' },
    { id: '1959', title: '1959' },
    { id: '1958', title: '1958' },
    { id: '1957', title: '1957' },
    { id: '1956', title: '1956' },
    { id: '1955', title: '1955' },
    { id: '1954', title: '1954' },
    { id: '1953', title: '1953' },
    { id: '1952', title: '1952' },
    { id: '1951', title: '1951' },
    { id: '1950', title: '1950' },
  ];
  const optTracksNumber = [
    { id: '1', title: '1' },
    { id: '2', title: '2' },
    { id: '3', title: '3' },
    { id: '4', title: '4' },
    { id: '5', title: '5' },
    { id: '6', title: '6' },
    { id: '7', title: '7' },
    { id: '8', title: '8' },
    { id: '9', title: '9' },
    { id: '10', title: '10' },
    { id: '11', title: '11' },
    { id: '12', title: '12' },
    { id: '13', title: '13' },
    { id: '14', title: '14' },
    { id: '15', title: '15' },
    { id: '16', title: '16' },
    { id: '17', title: '17' },
    { id: '18', title: '18' },
    { id: '19', title: '19' },
    { id: '20', title: '20' },
    { id: '21', title: '21' },
    { id: '22', title: '22' },
    { id: '23', title: '23' },
    { id: '24', title: '24' },
    { id: '25', title: '25' },
    { id: '26', title: '26' },
    { id: '27', title: '27' },
    { id: '28', title: '28' },
    { id: '29', title: '29' },
    { id: '30', title: '30' },
  ];
  const optContentLicense = [
    { id: 'free', title: 'free' },
    { id: 'plus', title: 'plus' },
  ];
  const optMPAA = [
    { id: 'G', title: 'G' },
    { id: 'PG', title: 'PG' },
    { id: 'PG-13', title: 'PG-13' },
    { id: 'R', title: 'R' },
    { id: 'NC-17', title: 'NC-17' },
  ];
  const optExplicitLyrics = [
    { id: 'Yes', title: 'Yes' },
    { id: 'Not', title: 'Not' },
  ];

  const objColumns = [
    {
      title: '',
      field: '_musStatus',
      formatter: 'html',
      width: 15,
      align: 'center',
    },
    {
      title: 'F Main',
      field: '_musFeaturedMain',
      formatter: 'html',
      width: 100,
      align: 'center',
    },
    {
      title: 'Featured',
      field: '_musFeatured',
      formatter: 'html',
      width: 110,
      align: 'center',
    },
    // {
    //   title: 'Album',
    //   field: '_musAlbum',
    //   headerFilter: 'input',
    // },
    { title: 'Name', field: 'musName', headerFilter: 'input' },
    { title: 'Genres', field: '_musGenre', headerFilter: 'input' },
    { title: 'Artist', field: '_musArtist', headerFilter: 'input' },
    {
      title: 'Created',
      field: '_musDateCreated',
      headerFilterPlaceholder: 'filter',
      headerFilter: 'input',
      align: 'center',
      width: 100,
    },
    {
      title: 'Updated',
      field: '_musDateUpdated',
      headerFilterPlaceholder: 'filter',
      headerFilter: 'input',
      align: 'center',
      width: 100,
    },
  ];
  const objColumnsArtist = [
    {
      title: '',
      field: '_artStatus',
      formatter: 'html',
      width: 15,
      align: 'center',
      // headerFilter: 'select',
      // headerFilterParams: {
      //   values: {
      //     0: 'Inactive',
      //     1: 'Active',
      //   },
      // },
    },
    { title: 'Name', field: 'artName', headerFilter: 'input' },
    {
      title: 'Created',
      field: '_artDateCreated',
      headerFilterPlaceholder: 'filter',
      headerFilter: 'input',
      align: 'center',
      width: 100,
    },
    {
      title: 'Updated',
      field: '_artDateUpdated',
      headerFilterPlaceholder: 'filter',
      headerFilter: 'input',
      align: 'center',
      width: 100,
    },
  ];

  const auxFormatDataGenre = data => {
    return { id: data.genId, title: data.genName };
  };
  const auxFormatDataArtist = data => {
    return { id: data.artId, title: data.artName };
  };

  const auxFormatData = objData => {
    objData.id = objData.sonId;
    objData._musStatus = led(objData.musStatus);
    objData._musFeaturedMain = led(objData.musFeaturedMain, true);
    objData._musFeatured = led(objData.musFeatured, true);
    objData._musAlbum = objData.musName;
    objData._musGenre = '';
    objData.GENRE.map(genre => {
      objData._musGenre += ` | ${genre.name}`;
    });
    objData._musGenre = objData._musGenre.substr(2);
    objData._musArtist = '';
    objData.ARTIST.map(artist => {
      objData._musArtist += ` | ${artist.name}`;
    });
    objData._musArtist = objData._musArtist.substr(2);
    objData._musDateCreated = format(
      new Date(objData.musDateCreated),
      'MM/dd/yyyy'
    );
    objData._musDateUpdated = format(
      new Date(objData.musDateUpdated),
      'MM/dd/yyyy'
    );
    return objData;
  };

  useEffect(() => {
    async function loadData() {
      // kes-dev-tmp
      const lstPromises = [
        api('GET', `/genre`, null),
        api('GET', `/artist`, null),
      ];

      const resGetData = await Promise.all(lstPromises);

      setGenre(resGetData[0].data.resData.map(auxFormatDataGenre));
      setArtist(resGetData[1].data.resData.map(auxFormatDataArtist));
      setMount(true);

      handleListLinks();
    }

    try {
      console.log(objDataMain);

      loadData();

      // let lstSongs = objDataMain !== null ? objDataMain.LINKS_TRAILER.map(auxFormatData) : [];
      // let lstFilms = objDataMain !== null ? objDataMain.LINKS.map(auxFormatData) : [];
      // const tmpDataSongs = lstSongs.concat(lstFilms);
      // console.log(tmpDataSongs);

      // setDataTable(tmpDataSongs);
      // setStatus(true);
    } catch (error) {
      console.error(error);
      viewError();
    }
  }, []);

  const fncGetLink = async medKey => {
    try {
      console.log('fncGetLink');
      // const apiGetData = api('GET', `/album/${objDataMain.id}/link/${medKey}`, null);
      // const resGetData = await apiGetData;
      // const {
      //   data: { resData },
      // } = resGetData;
      // console.log(resData);
      // return resData;

      // kes-dev-tmp
      if (medKey === 'link-trailer') {
        return objDataMain.LINKS_TRAILER[0];
      }
      let objSongTemp = null;
      for (let i = 0; i < objDataMain.LINKS.length; i++) {
        if (objDataMain.LINKS[i].medKey === medKey) {
          objSongTemp = objDataMain.LINKS[i];
        }
      }
      return objSongTemp;
    } catch (error) {
      N.error('Link', 'Error load');
      // setTimeout(() => {
      //   window.location.reload();
      // }, 500);
      return null;
    }
  };

  const auxFormatDataSongManage = data => {
    data.id = data.sonId;
    switch (data.musStatus) {
      case 0:
        data._musStatus = 'Active';
        break;
      case 1:
        data._musStatus = 'Processing';
        break;
      case 2:
        data._musStatus = 'Disabled';
        break;
      case 3:
        data._musStatus = 'Blocked';
        break;
      default:
        data._musStatus = 'Disabled';
        break;
    }
    switch (data.musFeaturedMain) {
      case 0:
        data._musFeaturedMain = 'Active';
        break;
      case 1:
        data._musFeaturedMain = 'Disabled';
        break;
      default:
        data._musFeaturedMain = 'Disabled';
        break;
    }
    switch (data.musFeatured) {
      case 0:
        data._musFeatured = 'Active';
        break;
      case 1:
        data._musFeatured = 'Disabled';
        break;
      default:
        data._musFeatured = 'Disabled';
        break;
    }
    for (let c = 0; c < data.GENRE.length; c++) {
      data[`musGenre${c + 1}`] = data.GENRE[c].genId || data.GENRE[c].id;
    }
    for (let c = 0; c < data.ARTIST.length; c++) {
      data[`musArtist${c + 1}`] = data.ARTIST[c].artId || data.ARTIST[c].id;
    }
    switch (data.musExplicitLyrics) {
      case 0:
        data._musExplicitLyrics = 'Yes';
        break;
      case 1:
        data._musExplicitLyrics = 'Not';
        break;
      default:
        data._musExplicitLyrics = 'Not';
        break;
    }
    data._musDateCreated = format(new Date(data.musDateCreated), 'MM/dd/yyyy');
    data._musDateUpdated = format(new Date(data.musDateUpdated), 'MM/dd/yyyy');
    return data;
  };

  const handleViewLink = async sonId => {
    console.log('handleViewLink');
    console.log(sonId);
    const lstResult = objDataMain.SONG.filter(song => song.sonId === sonId);
    let objResult = lstResult[0];
    console.log(objResult);

    objResult = auxFormatDataSongManage(objResult);
    console.log(objResult);

    setSong(objResult);
    setTemp({
      musThumbnailSquare: objResult.musThumbnailSquare,
    });
    setHlsLinkPlayer(objResult.musLinkStreamFull);
    setManagerDataNew(false);
    setManagerData(true);
  };

  const handleNewSong = () => {
    console.log('handleNewSong');
    console.log('new');
    setSong({
      albId: objDataMain.albId,
      musStatus: null,
      musDescription: '',
      labId: objDataMain.labId,
      musYearReleased: null,
      musTracksNumber: null,
      musExplicitLyrics: null,
      musName: '',
      musGenre: '',
      musArtist: '',
      musFeatured: null,
      musFeaturedMain: null,
    });
    setSongLinkPlayer(null);
    setManagerDataNew(true);
    setManagerData(true);
  };

  const handleListLinks = () => {
    console.log('handleListLinks');

    async function loadData() {
      const apiGetData = api('GET', `/album/${objDataMain.albId}`, null);

      const resGetData = await apiGetData;
      const {
        data: { resData },
      } = resGetData;
      console.log(resData);

      const lstSongs = resData !== null ? resData.SONG.map(auxFormatData) : [];

      const tmpData = resData !== null ? auxFormatDataAlbum(resData) : {};

      setDataMain(tmpData);
      setDataTable(lstSongs);
      setStatus(true);
      setManagerData(false);

      function auxFormatDataAlbum(data) {
        data.id = data.albId;
        switch (data.musStatus) {
          case 0:
            data._musStatus = 'Active';
            break;
          case 1:
            data._musStatus = 'Processing';
            break;
          case 2:
            data._musStatus = 'Disabled';
            break;
          case 3:
            data._musStatus = 'Blocked';
            break;
          default:
            data._musStatus = 'Disabled';
            break;
        }
        switch (data.musFeaturedMain) {
          case 0:
            data._musFeaturedMain = 'Active';
            break;
          case 1:
            data._musFeaturedMain = 'Disabled';
            break;
          default:
            data._musFeaturedMain = 'Disabled';
            break;
        }
        switch (data.musFeatured) {
          case 0:
            data._musFeatured = 'Active';
            break;
          case 1:
            data._musFeatured = 'Disabled';
            break;
          default:
            data._musFeatured = 'Disabled';
            break;
        }
        data._musGenre = '';
        data.GENRE.map(genre => {
          data._musGenre += ` | ${genre.genName}`;
        });
        data._musGenre = data._musGenre.substr(2);
        data._musArtist = '';
        data.ARTIST.map(artist => {
          data._musArtist += ` | ${artist.artName}`;
        });
        data._musArtist = data._musArtist.substr(2);
        switch (data.musExplicitLyrics) {
          case 0:
            data._musExplicitLyrics = 'Yes';
            break;
          case 1:
            data._musExplicitLyrics = 'Not';
            break;
          default:
            data._musExplicitLyrics = 'Not';
            break;
        }
        data._musDateCreated = format(
          new Date(data.musDateCreated),
          'MM/dd/yyyy'
        );
        data._musDateUpdated = format(
          new Date(data.musDateUpdated),
          'MM/dd/yyyy'
        );
        return data;
      }
    }

    try {
      loadData();
    } catch (error) {
      console.error(error);
      viewError();
    }
  };

  const handleSubmit = async data => {
    try {
      if (handleValidate()) {
        if (isManagerDataNew) {
          // viewLoading();
          setStatus(false);
          data = {
            ...data,
          };
          console.log(`Data POST`, data);
          const resPostData = await api('POST', `/song`, data);
          console.log(resPostData);

          N.success('Successfully created');

          setTimeout(() => {
            // history.push(`/album/${resPostData.data.resData.albId}`); aaaaaaaaaaaaaaaaaaaa
            // window.location.reload();

            // setStatus(true);
            handleListLinks();
          }, 1500);
        } else {
          setStatus(false);
          console.log(`Data PUT`, data);

          const resPutData = await api(
            'PUT',
            `/song/${objSong.sonId}/info`,
            data
          );
          console.log(resPutData);

          N.success('Successfully updated');

          setTimeout(() => {
            // window.location.reload(); aaaaaaaaaaaaaaaaaa
            setSong({ ...objSong, ...data });
            setStatus(true);
          }, 1500);
        }
      }

      function handleValidate() {
        let varControl = true;

        console.log(data);

        if (
          data._musStatus !== 'Active' &&
          data._musStatus !== 'Processing' &&
          data._musStatus !== 'Disabled' &&
          data._musStatus !== 'Blocked'
        ) {
          varControl = false;
          N.error('Status', 'Check parameters');
        } else {
          if (
            isManagerDataNew ||
            (objDataMain.musStatus !== 1 && objDataMain.musStatus !== 3)
          ) {
            if (
              data._musStatus !== 'Active' &&
              data._musStatus !== 'Disabled'
            ) {
              varControl = false;
              N.error('Status', 'Check parameters');
            }
          }
          switch (data._musStatus) {
            case 'Active':
              data.musStatus = 0;
              break;
            case 'Processing':
              data.musStatus = 1;
              break;
            case 'Disabled':
              data.musStatus = 2;
              break;
            case 'Blocked':
              data.musStatus = 3;
              break;
            default:
              varControl = false;
              N.error('Error Status', 'Check parameters');
              break;
          }
        }

        data.albId = objDataMain.albId;

        data.musTracksNumber = parseInt(data.musTracksNumber);

        if (
          data._musFeaturedMain !== 'Active' &&
          data._musFeaturedMain !== 'Disabled'
        ) {
          varControl = false;
          N.error('Featured Main', 'Check parameters');
        } else {
          switch (data._musFeaturedMain) {
            case 'Active':
              data.musFeaturedMain = 0;
              break;
            case 'Disabled':
              data.musFeaturedMain = 1;
              break;
            default:
              varControl = false;
              N.error('Featured Main', 'Check parameters');
              break;
          }
        }

        if (
          data._musFeatured !== 'Active' &&
          data._musFeatured !== 'Disabled'
        ) {
          varControl = false;
          N.error('Featured', 'Check parameters');
        } else {
          switch (data._musFeatured) {
            case 'Active':
              data.musFeatured = 0;
              break;
            case 'Disabled':
              data.musFeatured = 1;
              break;
            default:
              varControl = false;
              N.error('Featured', 'Check parameters');
              break;
          }
        }

        if (data.musContentLicense === '') {
          varControl = false;
          N.error('Content License', 'Check parameters');
        }

        if (data.musName === '') {
          varControl = false;
          N.error('Name', 'Check parameters');
        }

        if (data.musDescription === '') {
          varControl = false;
          N.error('Description', 'Check parameters');
        }

        data.musYearReleased = parseInt(data.musYearReleased);

        const lstGenresTemp = [];
        if (data.musGenre1 === '' || data.musGenre1 === ' ') {
          varControl = false;
          N.error('Genre', 'Check parameters');
        } else {
          let tmpGenre = '';
          // 1
          tmpGenre = data.musGenre1;
          lstGenresTemp.push(tmpGenre);
          // 2
          if (data.musGenre2 !== '' && data.musGenre2 !== ' ') {
            tmpGenre = data.musGenre2;
            lstGenresTemp.push(tmpGenre);
          }
          // 3
          if (data.musGenre3 !== '' && data.musGenre3 !== ' ') {
            tmpGenre = data.musGenre3;
            lstGenresTemp.push(tmpGenre);
          }
        }
        data.musGenre = lstGenresTemp.filter(function(item, index) {
          return lstGenresTemp.indexOf(item) === index;
        });

        if (
          data._musExplicitLyrics !== 'Yes' &&
          data._musExplicitLyrics !== 'Not'
        ) {
          varControl = false;
          N.error('Explicit Lyrics', 'Check parameters');
        } else {
          switch (data._musExplicitLyrics) {
            case 'Yes':
              data.musExplicitLyrics = 0;
              break;
            case 'Not':
              data.musExplicitLyrics = 1;
              break;
            default:
              varControl = false;
              N.error('Explicit Lyrics', 'Check parameters');
              break;
          }
        }

        const lstArtistsTemp = [];
        if (data.musArtist1 === '' || data.musArtist1 === ' ') {
          varControl = false;
          N.error('Artist', 'Check parameters');
        } else {
          let tmpArtist = '';
          // 1
          tmpArtist = data.musArtist1;
          lstArtistsTemp.push(tmpArtist);
          // 2
          if (data.musArtist2 !== '' && data.musArtist2 !== ' ') {
            tmpArtist = data.musArtist2;
            lstArtistsTemp.push(tmpArtist);
          }
          // 3
          if (data.musArtist3 !== '' && data.musArtist3 !== ' ') {
            tmpArtist = data.musArtist3;
            lstArtistsTemp.push(tmpArtist);
          }
        }
        data.musArtist = lstArtistsTemp.filter(function(item, index) {
          return lstArtistsTemp.indexOf(item) === index;
        });

        return varControl;
      }
    } catch (error) {
      console.error(error);
      console.error(error.response.data);
      if (error.response.status === 409) {
        N.error(error.response.data.resMessage, 'Error save');
        setTimeout(() => {
          setSong({ ...objSong, ...data });
          setStatus(true);
        }, 1500);
      } else {
        N.error('Error save');
      }
      // viewContent();
    }
  };

  async function getDuration(file) {
    return new Promise(resolve => {
      const objectURL = URL.createObjectURL(file);
      const mySound = new Audio([objectURL]);
      mySound.addEventListener(
        'canplaythrough',
        e => {
          const seconds = e.currentTarget.duration;
          console.log(seconds);
          URL.revokeObjectURL(objectURL);
          resolve({
            file,
            duration_s: mySound.duration,
            duration_ms: Math.round(mySound.duration * 1000),
          });
        },
        false
      );
    });
  }

  async function getDuration2(file) {
    const url = URL.createObjectURL(file);

    return new Promise(resolve => {
      const audio = document.createElement('audio');
      audio.muted = true;
      const source = document.createElement('source');
      source.src = url; // --> blob URL
      audio.preload = 'metadata';
      audio.appendChild(source);
      audio.onloadedmetadata = function() {
        resolve(audio.duration);
      };
    });
  }

  const songHandleUpload = async file => {
    console.log('songHandleUpload');
    console.log(file[0]);
    const objFile = await getDuration(file[0]);
    console.log(objFile);

    // Referencing file
    // file.current = file[0];

    if (
      objFile.file &&
      objFile.file.size > config.S3_MUSIC.MAX_ATTACHMENT_SIZE
    ) {
      // console.log("sim");
      N.error(
        `Please pick a file smaller than ${(
          config.S3_MUSIC.MAX_ATTACHMENT_SIZE / 1000000000
        ).toFixed(2)} GB.`,
        'Click me!'
      );
      return;
    }

    const myIntervalProcessing = setInterval(() => {
      console.log('myIntervalProcessing');
    }, 1000);
    clearInterval(myIntervalProcessing);

    let fileUpload = null;
    try {
      setUpProgress(0);
      setIsDropzone(false);
      setModUpload(true);

      fileUpload = objFile.file
        ? await fileUploadMedia(
            'music',
            objSong.sonId,
            objFile.file,
            progress => {
              if (progress < 94) {
                setUpProgress(progress);
              }
            }
          )
        : null;
      console.log(fileUpload);

      if (fileUpload) {
        setUpProgress(95);

        const resSongPut = await api('PUT', `/song/${objSong.sonId}/stream`, {
          musLinkStreamFull: fileUpload.link,
          musLinkStreamAds: fileUpload.link,
          musDuration: objFile.duration_ms,
          albId: objDataMain.albId,
        });
        console.log(resSongPut);

        if (resSongPut) {
          setUpProgress(100);
          setTimeout(() => {
            setIsDropzone(true);
            setModUpload(false);
            setHlsLinkPlayer(fileUpload.link);
            // handleListLinks();
          }, 1500);
        }
      }
    } catch (e) {
      console.error(e);
      N.error(`Error upload`);
      setIsDropzone(true);
      setModUpload(false);
    }
  };

  const songHandleUploadError = file => {
    console.log('songHandleUploadError');
    console.error(file);
  };

  const handleCloseArtist = async () => {
    setModArtist(false);
  };

  const handleChangeFileFrame = async img => {
    console.log('#musThumbnailSquare => Upload -> Start');

    // console.log(img);
    try {
      setStatus(false);
      const fileUpload = await fileUploadImg(
        'MEDIA',
        objSong.medId,
        'frame',
        img[0]
      );
      console.log(fileUpload);

      // Validate Resolutions
      const comImage = document.createElement('img');
      comImage.src = fileUpload.link;
      comImage.onload = function() {
        // console.log(comImage.naturalWidth, comImage.naturalHeight);

        if (comImage.naturalWidth !== 1920 || comImage.naturalHeight !== 1080) {
          N.error('Image', 'Incorrect resolution');
          setStatus(true);
          return false;
        }

        setTemp(prevState => {
          return {
            ...prevState,
            musThumbnailSquare: fileUpload.link,
          };
        });

        handleSubmitFrame(fileUpload.link);

        console.log('#musThumbnailSquare => Upload -> End');
      };
    } catch (e) {
      console.error(e);
      setStatus(true);
      console.log('#musThumbnailSquare => Upload -> Error');
    }
  };

  const handleSubmitFrame = async link => {
    try {
      // viewLoading();
      const data = {
        medKey: objSong.medKey,
        musThumbnailSquare: link,
      };
      console.log(`Data PUT`, data);
      const resPutData = await api(
        'PUT',
        `/album/${objSong.medId}/link/image`,
        data
      );
      console.log(resPutData);

      N.success('Successfully updated');

      setTimeout(() => {
        setStatus(true);
      }, 1000);
    } catch (error) {
      console.error(error);
      N.error('Error updating');
      setStatus(true);
      // viewContent();
    }
  };

  function toggle(value) {
    return !value;
  }

  return (
    <>
      {isStatus ? (
        !isManagerData ? (
          <>
            <S.DivNew>
              <ButtonNew propClickAction={handleNewSong} propText="New Song" />
            </S.DivNew>
            <Table
              propColumns={objColumns}
              propData={lstData}
              propDblClickRedirect="undefined"
              propDblClickAction={handleViewLink}
            />
          </>
        ) : (
          <>
            <S.DivBack>
              <ButtonBack propClickAction={handleListLinks} propText="Back" />
            </S.DivBack>
            <S.DivContent>
              <S.DivInput propW={isManagerDataNew ? '100%' : '60%'}>
                {mount && (
                  <S.Form onSubmit={handleSubmit} initialData={objSong}>
                    <S.DivInput propW="25%">
                      <small>Status</small>
                      <S.Select
                        name="_musStatus"
                        options={
                          isManagerDataNew ||
                          (objSong.musStatus !== 1 && objSong.musStatus !== 3)
                            ? optStatusSimple
                            : optStatus
                        }
                        disabled={
                          objSong.musStatus === 1 || objSong.musStatus === 3
                        }
                      />
                    </S.DivInput>
                    <S.DivInput propW="25%">
                      <small>Date Created</small>
                      <S.Input name="_musDateCreated" disabled />
                    </S.DivInput>
                    <S.DivInput propW="25%">
                      <small>Date Updated</small>
                      <S.Input name="_musDateUpdated" disabled />
                    </S.DivInput>
                    <S.DivInput propW="25%">
                      <small>Popularity</small>
                      <RatingView propValue={3.5} />
                      {/* <S.Input name="musRating" disabled /> */}
                    </S.DivInput>
                    <S.DivInput propW="25%">
                      <small>Album</small>
                      <S.Input name="_musAlbum" disabled />
                    </S.DivInput>
                    <S.DivInput propW="25%">
                      <small>Tracks Number</small>
                      <S.Select
                        name="musTracksNumber"
                        options={optTracksNumber}
                        disabled={false}
                      />
                    </S.DivInput>
                    <S.DivInput propW="25%">
                      <small>Featured Main</small>
                      <S.Select
                        name="_musFeaturedMain"
                        options={optFeatured}
                        disabled={false}
                      />
                    </S.DivInput>
                    <S.DivInput propW="25%">
                      <small>Featured</small>
                      <S.Select
                        name="_musFeatured"
                        options={optFeatured}
                        disabled={false}
                      />
                    </S.DivInput>
                    <S.DivInput propW="25%">
                      <small>Content License</small>
                      <S.Select
                        name="musContentLicense"
                        options={optContentLicense}
                        disabled={false}
                      />
                    </S.DivInput>
                    <S.DivInput propW="25%">
                      <small>Name</small>
                      <S.Input name="musName" disabled={false} />
                    </S.DivInput>
                    <S.DivInput propW="50%">
                      <small>Description</small>
                      <S.Input name="musDescription" disabled={false} />
                    </S.DivInput>
                    <S.DivInput propW="25%">
                      <small>Year Released</small>
                      <S.Select
                        name="musYearReleased"
                        options={optYearReleased}
                        disabled={false}
                      />
                    </S.DivInput>
                    <S.DivInput propW="25%">
                      <small>Genre 1</small>
                      <S.Select
                        name="musGenre1"
                        options={[...optGenre, { id: '', title: '' }]}
                        disabled={false}
                      />
                    </S.DivInput>
                    <S.DivInput propW="25%">
                      <small>Genre 2</small>
                      <S.Select
                        name="musGenre2"
                        options={[...optGenre, { id: '', title: '' }]}
                        disabled={false}
                      />
                    </S.DivInput>
                    <S.DivInput propW="25%">
                      <small>Genre 3</small>
                      <S.Select
                        name="musGenre3"
                        options={[...optGenre, { id: '', title: '' }]}
                        disabled={false}
                      />
                    </S.DivInput>
                    <S.DivInput propW="25%">
                      <small>Explicit Lyrics</small>
                      <S.Select
                        name="_musExplicitLyrics"
                        options={optExplicitLyrics}
                        disabled={false}
                      />
                    </S.DivInput>
                    <S.DivInput propW="25%">
                      <small>Artist 1</small>
                      <S.Select
                        name="musArtist1"
                        options={[...optArtist, { id: '', title: '' }]}
                        disabled={false}
                      />
                    </S.DivInput>
                    <S.DivInput propW="25%">
                      <small>Artist 2</small>
                      <S.Select
                        name="musArtist2"
                        options={[...optArtist, { id: '', title: '' }]}
                        disabled={false}
                      />
                    </S.DivInput>
                    <S.DivInput propW="25%">
                      <small>Artist 3</small>
                      <S.Select
                        name="musArtist3"
                        options={[...optArtist, { id: '', title: '' }]}
                        disabled={false}
                      />
                    </S.DivInput>
                    <S.DivButton>
                      <ButtonLoader propTitle="SAVE" propSpinner={false} />
                    </S.DivButton>
                  </S.Form>
                )}
              </S.DivInput>
              {!isManagerDataNew && (
                <>
                  <S.DivInput propBorder propW="20%">
                    <S.DivInput propW="100%">
                      <S.Title>
                        Thumbnail Frame
                        <br />
                        <small>1920px x 1080px</small>
                      </S.Title>
                      {/* <ButtonAdd propClickAction={handleViewArtist} /> */}
                    </S.DivInput>
                    <S.DivInput propW="100%">
                      <S.LabelInputImageFrame htmlFor="file-frame">
                        <img
                          src={objTemp.musThumbnailSquare}
                          alt="Thumbnail Frame"
                          onError={() =>
                            setTemp(prevState => {
                              return {
                                ...prevState,
                                tmpLinkThumbnailFrame:
                                  objDefault.musThumbnailSquare,
                              };
                            })
                          }
                        />
                        {/* <input
                          id="file-frame"
                          type="file"
                          accept="image/*"
                          onChange={e => handleChangeFileFrame(e.target.files)}
                        /> */}
                      </S.LabelInputImageFrame>
                    </S.DivInput>
                  </S.DivInput>
                  <S.DivInput propBorder propW="20%">
                    <S.DivInput propW="100%">
                      <S.Title>
                        Song
                        <br />
                        <small>player</small>
                      </S.Title>
                      {/* <ButtonAdd propClickAction={handleViewArtist} /> */}
                    </S.DivInput>
                    <S.DivInput propW="100%">
                      {hlsLinkPlayer ? (
                        <ReactPlayer
                          ref={player}
                          url={hlsLinkPlayer}
                          playing={false}
                          controls
                          // light
                          width="100%"
                          height="100%"
                          // pip={true}
                          onStart={() => {
                            console.log('start');
                            // setError(false);
                          }}
                          onError={e => {
                            console.error(e);
                            // setError(true);
                          }}
                        />
                      ) : (
                        <h1>Error Player</h1>
                      )}
                    </S.DivInput>
                    <S.DivInput propW="100%">
                      <Dropzone
                        disabled={!isDropzone || objSong.musStatus === 1}
                        onDropAccepted={songHandleUpload}
                        accept={['audio/*']}
                        multiple={false}
                        onDropRejected={songHandleUploadError}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <S.Dropzone
                            propEnabled={isDropzone}
                            // className={
                            //   !isDropzone
                            //     ? classes.divDropzone
                            //     : classes.divDropzoneDisabled
                            // }
                            {...getRootProps()}
                          >
                            {/* getRootProps -> Carrega todas as propriedades e adiciona
                              ... -> adiciona todo o conteudo mais esse */}
                            <input {...getInputProps()} />

                            <p>
                              {isDropzone && objSong.musStatus !== 1
                                ? 'Drag the file or click here!'
                                : objSong.musStatus === 1
                                ? 'Your song is being processed!'
                                : 'Uploading ... (please wait)'}
                            </p>
                          </S.Dropzone>
                        )}
                      </Dropzone>
                    </S.DivInput>
                  </S.DivInput>
                </>
              )}
            </S.DivContent>
            {modUpload && (
              <S.Modal>
                <S.ModalDivUpload>
                  <S.ModalHeader>Upload Progress</S.ModalHeader>
                  <S.Hr />
                  {upProgress === 100 ? (
                    <S.ProgressImg propPadding src={imgSuccess} alt="..." />
                  ) : (
                    <S.ProgressImg
                      propPadding={false}
                      src={!isSearch ? imgLoading : imgSearch}
                      alt="..."
                    />
                  )}
                  <CustomLinearProgress
                    variant="determinate"
                    color="primary"
                    value={upProgress}
                  />
                </S.ModalDivUpload>
              </S.Modal>
            )}
            {/* {modArtist && (
              <S.Modal>
                <S.ModalDivArtist>
                  <S.ModalHeader>Insert Artist</S.ModalHeader>
                  <S.Hr />
                  <br />
                  <S.DivBack>
                    <ButtonBack
                      propClickAction={handleCloseArtist}
                      propText="Cancel"
                    />
                  </S.DivBack>
                  <Table
                    propColumns={objColumnsArtist}
                    propData={lstArtist}
                    propDblClickRedirect="undefined"
                    propDblClickAction={handleAddArtist}
                  />
                </S.ModalDivArtist>
              </S.Modal>
            )} */}
          </>
        )
      ) : (
        <Loader />
      )}
    </>
  );
};

Song.defaultProps = {
  propData: {},
};

Song.propTypes = {
  propData: PropTypes.object,
};

export default Song;
