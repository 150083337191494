import styled from 'styled-components';
import { Error } from '@styled-icons/boxicons-regular';

export const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: #f0a71f0f;
  &:hover {
    cursor: progress;
  }
`;

export const ContainerError = styled.div`
  width: 80px;
  height: 80px;
`;

export const ImgError = styled(Error)`
  color: red;
`;

export const Button = styled.button`
  width: 100%;
  height: 45px;
  background: white;
  color: red;
  border: 1px solid red;
  border-radius: 5px;
  transition: all 2s;

  &:hover {
    background: #ffd8d8;
  }
`;
