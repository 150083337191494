import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { NotificationContainer } from 'react-notifications';
import { NotificationManager as N } from 'react-notifications';

import api from '../../../../services/api';
import { fileUploadImg, fileUploadAlbumImg } from '../../../../services/s3';
import LiveManagerContext from '../../LiveManagerContext';

import ButtonLoader from '../../../../components/ButtonLoader';
import RatingView from '../../../../components/RatingView';

import 'react-notifications/lib/notifications.css';
import * as S from './styles';

const Image = ({ propData }) => {

  const { viewLoading, viewError, viewContent } = useContext(LiveManagerContext);

  const [objDefault, setDefault] = useState({
    livThumbnailBanner: "https://cdn.live.pdtvpro.com/liveBanner.png",
    livThumbnailLand: "https://cdn.live.pdtvpro.com/liveThumbnailLand.png",
    livThumbnailPort: "https://cdn.live.pdtvpro.com/liveThumbnailPort.png",
  })
  const [objData, setData] = useState(propData)
  const [objTemp, setTemp] = useState({
    livThumbnailBanner: objData.livThumbnailBanner,
    livThumbnailLand: objData.livThumbnailLand,
    livThumbnailPort: objData.livThumbnailPort
  })
  // console.log(objData);

  const optStatus = [
    { id: 'Active', title: 'Active' },
    // { id: 'Processing', title: 'Processing' },
    { id: 'Disabled', title: 'Disabled' },
    // { id: 'Blocked', title: 'Blocked' },
  ];

  const handleCheckFiles = data => {
    console.log('#btn => Images -> Chek File');
    console.log(data)
  }

  const handleChangeFileSquare = async img => {
    console.log('#livThumbnailBanner => Upload -> Start');
    console.log(img);
    try {
      const fileUpload = await fileUploadAlbumImg(objData.albId, 'square', img[0]);
      console.log(fileUpload);

      // Validate Resolutions
      let comImage = document.createElement('img');
      comImage.src = fileUpload.link;
      comImage.onload = function() {
        // console.log(comImage.naturalWidth, comImage.naturalHeight);

        if (comImage.naturalWidth !== 1024 || comImage.naturalHeight !== 1024) {
          N.error('Image', 'Incorrect resolution');
          return false;
        }

        setTemp(prevState => {
          return {
            ...prevState,
            livThumbnailBanner: fileUpload.link,
          };
        });
        console.log('#livThumbnailBanner => Upload -> End');
      }
    } catch (e) {
      console.error(e);
      console.log('#livThumbnailBanner => Upload -> Error');
    }
  }

  const handleChangeFileBanner = async img => {
    console.log('#livThumbnailBanner => Upload -> Start');

    // console.log(img);
    try {
      const fileUpload = await fileUploadImg(
        'LIVE',
        `${objData.livId}-${objData.livLanguage}`,
        'banner',
        img[0]
      );
      console.log(fileUpload);

      // Validate Resolutions
      let comImage = document.createElement('img');
      comImage.src = fileUpload.link;
      comImage.onload = function() {
        // console.log(comImage.naturalWidth, comImage.naturalHeight);

        if (comImage.naturalWidth !== 3840 || comImage.naturalHeight !== 2160) {
          N.error('Image', 'Incorrect resolution');
          return false;
        }

        setTemp(prevState => {
          return {
            ...prevState,
            livThumbnailBanner: fileUpload.link,
          };
        });
        console.log('#livThumbnailBanner => Upload -> End');
      }
    } catch (e) {
      console.error(e);
      console.log('#livThumbnailBanner => Upload -> Error');
    }
  };

  const handleChangeFileLand = async img => {
    console.log('#livThumbnailLand => Upload -> Start');

    // console.log(img);
    try {
      const fileUpload = await fileUploadImg(
        'LIVE',
        `${objData.livId}-${objData.livLanguage}`,
        'land',
        img[0]
      );
      console.log(fileUpload);

      // Validate Resolutions
      let comImage = document.createElement('img');
      comImage.src = fileUpload.link;
      comImage.onload = function() {
        // console.log(comImage.naturalWidth, comImage.naturalHeight);

        if (comImage.naturalWidth !== 1920 || comImage.naturalHeight !== 1080) {
          N.error('Image', 'Incorrect resolution');
          return false;
        }

        setTemp(prevState => {
          return {
            ...prevState,
            livThumbnailLand: fileUpload.link,
          };
        });
        console.log('#livThumbnailLand => Upload -> End');
      }
    } catch (e) {
      console.error(e);
      console.log('#livThumbnailLand => Upload -> Error');
    }
  };

  const handleChangeFilePort = async img => {
    console.log('#livThumbnailPort => Upload -> Start');

    // console.log(img);
    try {
      const fileUpload = await fileUploadImg(
        'LIVE',
        `${objData.livId}-${objData.livLanguage}`,
        'port',
        img[0]
      );
      console.log(fileUpload);

      // Validate Resolutions
      let comImage = document.createElement('img');
      comImage.src = fileUpload.link;
      comImage.onload = function() {
        // console.log(comImage.naturalWidth, comImage.naturalHeight);

        if (comImage.naturalWidth !== 1040 || comImage.naturalHeight !== 1400) {
          N.error('Image', 'Incorrect resolution');
          return false;
        }

        setTemp(prevState => {
          return {
            ...prevState,
            livThumbnailPort: fileUpload.link,
          };
        });
        console.log('#livThumbnailPort => Upload -> End');
      }
    } catch (e) {
      console.error(e);
      console.log('#livThumbnailPort => Upload -> Error');
    }
  };

  const handleSubmit = async data => {
    try {
      // viewLoading();
      const data = {
        livThumbnailBanner : objTemp.livThumbnailBanner,
        livThumbnailLand : objTemp.livThumbnailLand,
        livThumbnailPort : objTemp.livThumbnailPort
      }
      console.log(`Data PUT`, data);
      const resPutData = await api('PUT', `/live/${objData.livLanguage}/image`, data);
      console.log(resPutData);

      N.success('Successfully updated');

      setTimeout(() => {
        // history.push('/');
        // viewContent();
      }, 1000);

    } catch (error) {
      console.error(error);
      N.error('Error updating');
      // viewContent();
    }
  }

  return (
    <>
      <S.Form onSubmit={handleCheckFiles}>
        <S.DivInput propW="45%">
          <small>
            Banner <small>3840px x 2160px</small>
          </small>
          <S.LabelInputImage htmlFor="file-banner">
            <img
              src={objTemp.livThumbnailBanner}
              alt="Thumbnail Banner"
              onError={() =>
                setTemp(prevState => {
                  return {
                    ...prevState,
                    tmpThumbnailBanner: objDefault.livThumbnailBanner,
                  };
                })
              }
            />
            <input
              id="file-banner"
              type="file"
              accept="image/*"
              onChange={e => handleChangeFileBanner(e.target.files)}
            />
          </S.LabelInputImage>
        </S.DivInput>
        <S.DivInput propW="20%">
          <small>
            Portrait <small>1040px x 1400px</small>
          </small>
          <S.LabelInputImage htmlFor="file-port">
            <img
              src={objTemp.livThumbnailPort}
              alt="Thumbnail Port"
              onError={() =>
                setTemp(prevState => {
                  return {
                    ...prevState,
                    tmpThumbnailPort: objDefault.livThumbnailPort,
                  };
                })
              }
            />
            <input
              id="file-port"
              type="file"
              accept="image/*"
              onChange={e => handleChangeFilePort(e.target.files)}
            />
          </S.LabelInputImage>
        </S.DivInput>
        <S.DivInput propW="35%">
          <small>
            Landscape <small>1920px x 1080px</small>
          </small>
          <S.LabelInputImage htmlFor="file-land">
            <img
              src={objTemp.livThumbnailLand}
              alt="Thumbnail Land"
              onError={() =>
                setTemp(prevState => {
                  return {
                    ...prevState,
                    tmpThumbnailLand: objDefault.livThumbnailLand,
                  };
                })
              }
            />
            <input
              id="file-land"
              type="file"
              accept="image/*"
              onChange={e => handleChangeFileLand(e.target.files)}
            />
          </S.LabelInputImage>
        </S.DivInput>
      </S.Form>
      <S.Form onSubmit={handleSubmit}>
        <S.DivButton>
          <ButtonLoader propTitle="SAVE" propSpinner={false} />
        </S.DivButton>
      </S.Form>
      <NotificationContainer />
    </>
  );
};

Image.defaultProps = {
  propData: {},
};

Image.propTypes = {
  propData: PropTypes.object,
};

export default Image;
