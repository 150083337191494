import styled from 'styled-components';
import Loader from 'react-loader-spinner';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

export const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: #f0a71f0f;
  &:hover {
    cursor: progress;
  }
`;

export const Spinner = styled(Loader)``;
