import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const ButtonLoaderAnimated = ({ propTitle, propSpinner }) => {
  return (
    <S.Button type="submit" disabled={propSpinner}>
      {propSpinner && (
        <S.Spinner type="Bars" color="#053877" height={25} width={25} />
      )}
      {propTitle}
    </S.Button>
  );
};

ButtonLoaderAnimated.defaultProps = {
  propTitle: 'TITLE',
};

ButtonLoaderAnimated.propTypes = {
  propTitle: PropTypes.string,
  propSpinner: PropTypes.bool.isRequired,
};

export default ButtonLoaderAnimated;
