import React, { useState, useEffect } from 'react';

import { format } from 'date-fns';
import { NotificationContainer } from 'react-notifications';
import api from '../../services/api';
import ArtistManagerContext from './ArtistManagerContext';

import NavBar from '../../components/NavBar';
import Loader from '../../components/Loader';
import Error from '../../components/Error';
import Line from '../../components/Line';
import NavTabsGeneral from '../../components/NavTabsGeneral';

import TabInfo from './Tabs/Info';
import TabImage from './Tabs/Image';
// import TabMedia from './Tabs/Media';

import * as S from './styles';

const ArtistManager = props => {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [isContent, setContent] = useState(false);
  const [isNewData, setNewData] = useState(false);
  const [isNewDataId, setNewDataId] = useState('');
  const [objData, setData] = useState({});

  const auxFormatData = data => {
    data.id = data.artId;
    switch (data.artStatus) {
      case 0:
        data._artStatus = 'Active';
        break;
      case 2:
        data._artStatus = 'Disabled';
        break;
      default:
        data._artStatus = 'Disabled';
        break;
    }
    switch (data.artFeaturedMain) {
      case 0:
        data._artFeaturedMain = 'Active';
        break;
      case 1:
        data._artFeaturedMain = 'Disabled';
        break;
      default:
        data._artFeaturedMain = 'Disabled';
        break;
    }
    switch (data.artFeatured) {
      case 0:
        data._artFeatured = 'Active';
        break;
      case 1:
        data._artFeatured = 'Disabled';
        break;
      default:
        data._artFeatured = 'Disabled';
        break;
    }
    data._artDateCreated = format(new Date(data.artDateCreated), 'MM/dd/yyyy');
    data._artDateUpdated = format(new Date(data.artDateUpdated), 'MM/dd/yyyy');
    return data;
  };

  useEffect(() => {
    async function loadData() {
      const {
        match: {
          params: { id },
        },
      } = props;

      if (id === 'new') {
        // New Data
        const {
          location: {
            search,
          },
        } = props;
        if (search !== '') {
          const parameterCreateArtist = search.substr(6);
          setNewDataId(parameterCreateArtist);
        } else {
          setNewDataId('undefined');
        }
        setNewData(true);
        viewContent();
      } else {
        const apiGetData = api('GET', `/artist/${id}`, null);

        const resGetData = await apiGetData;
        const {
          data: { resData },
        } = resGetData;
        console.log(resData);
        setData(auxFormatData(resData));
        viewContent();
      }
    }
    try {
      loadData();
    } catch (error) {
      viewError();
    }
  }, []);

  const viewData = () => {
    return (
      <S.DivData>
        <NavTabsGeneral
          propContext={objData}
          propTabs={[
            {
              title: 'Info',
              component: <TabInfo propData={objData} propNewData={isNewData} propNewDataId={isNewDataId} />,
            },
            !isNewData && {
              title: 'Images',
              component: <TabImage propData={objData} />,
            },
            // !isNewData && {
            //   title: 'Musics',
            //   component: <TabMedia propData={objData} />,
            // },
          ]}
        />
      </S.DivData>
    );
  };

  const viewLoading = status => {
    if (status === undefined) {
      console.log('type A');
      setContent(false);
      setError(false);
      setLoading(true);
    } else {
      console.log('type B');
      setLoading(status);
    }
  };

  const viewError = () => {
    setContent(false);
    setLoading(false);
    setError(true);
  };

  const viewContent = () => {
    setLoading(false);
    setError(false);
    setContent(true);
  };

  return (
    <>
      <NavBar />
      <S.Container>
        <S.ContainerHeader>
          <S.Title>Artist</S.Title>
          <Line />
        </S.ContainerHeader>
        <S.ContainerContent>
          <ArtistManagerContext.Provider
            value={{ viewLoading, viewError, viewContent }}
          >
            {isLoading && <Loader />}
            {isError && <Error />}
            {isContent && viewData()}
            {/* {isLoading ? <Loader /> : viewData()} */}
          </ArtistManagerContext.Provider>
        </S.ContainerContent>
      </S.Container>
      <NotificationContainer />
    </>
  );
};

export default ArtistManager;
