// Engaged Tv ADM
export default {
  info: {
    NAME: 'parade-app',
  },
  api: {
    BASE_URL: 'api.pdtvpro.com',
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'oi2r6kw2c5', // app-dev
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_yVIj0c0C3",
    APP_CLIENT_ID: "3fjn8e1cjbl29sget4hio0amr9",
    IDENTITY_POOL_ID: "us-east-1:20ab9102-04e6-4a78-bc7d-1be53c6fe25e"
  },
  pinpoint: {
    APP_ID: "40f0e9def6ea4813b4916e0d0709b240",
    REGION: "us-east-1",
  },
  // --------------------------------------------------------------------------------- S3 AUTH -----
  S3_THUMBNAILS_PROFILE: {
    REGION: "us-east-1",
    BUCKET: "parade-thumbnails-profile",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_INFLUENCER: {
    REGION: "us-east-1",
    BUCKET: "parade-thumbnails-influencer",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  // ------------------------------------------------------------------------------- S3 MOVIES -----
  S3_THUMBNAILS_CATEGORY: {
    REGION: "us-east-1",
    BUCKET: "parade-thumbnails-catalog",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_LIVE: {
    REGION: "us-east-1",
    BUCKET: "parade-thumbnails-live",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_CATALOG: {
    REGION: "us-east-1",
    BUCKET: "parade-thumbnails-catalog",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_MEDIA: {
    REGION: "us-east-1",
    BUCKET: "parade-thumbnails-media",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  // --------------------------------------------------------------------------------- S3 FILM -----
  S3_MEDIA_FILM: {
    REGION: "us-east-1",
    CLOUD_FRONT: "parade-vod-movies-source71e471f1-yiazsjt9apwo",
    BUCKET: "parade-vod-movies-source71e471f1-yiazsjt9apwo",
    MAX_ATTACHMENT_SIZE: 10000000000, //10 GB
  },
  // -------------------------------------------------------------------------------- S3 MUSIC -----
  S3_THUMBNAILS_GENRE: {
    REGION: "us-east-1",
    BUCKET: "parade-thumbnails-genre",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_LABEL: {
    REGION: "us-east-1",
    BUCKET: "parade-thumbnails-label",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_ARTIST: {
    REGION: "us-east-1",
    BUCKET: "parade-thumbnails-artist",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  S3_THUMBNAILS_ALBUM: {
    REGION: "us-east-1",
    BUCKET: "parade-thumbnails-album",
    MAX_ATTACHMENT_SIZE: 300000000, //300 Mb
  },
  // -------------------------------------------------------------------------------- S3 MUSIC -----
  S3_MUSIC: {
    REGION: "us-east-1",
    CLOUD_FRONT: "https://djl7bgsm8h169.cloudfront.net",
    BUCKET: "parade-vod-musics",
    MAX_ATTACHMENT_SIZE: 1000000000, //1 GB
  },
};
