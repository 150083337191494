import React, { useState, useEffect } from 'react';

import { format } from 'date-fns';
import api from '../../services/api';

import NavBar from '../../components/NavBar';
import Table from '../../components/Table';
import Loader from '../../components/Loader';
import Error from '../../components/Error';
import Line from '../../components/Line';
import ButtonNew from '../../components/ButtonNew';

import * as S from './styles';
import { led } from '../../utils/led';

const Song = () => {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [lstData, setData] = useState([]);
  const objColumns = [
    {
      title: '',
      field: '_musStatus',
      formatter: 'html',
      width: 15,
      align: 'center',
    },
    {
      title: 'F Main',
      field: '_musFeaturedMain',
      formatter: 'html',
      width: 100,
      align: 'center',
    },
    {
      title: 'Featured',
      field: '_musFeatured',
      formatter: 'html',
      width: 110,
      align: 'center',
    },
    {
      title: 'Album',
      field: '_musAlbum',
      headerFilter: 'input',
    },
    { title: 'Name', field: 'musName', headerFilter: 'input' },
    { title: 'Genres', field: '_musGenre', headerFilter: 'input' },
    { title: 'Artist', field: '_musArtist', headerFilter: 'input' },
    {
      title: 'Created',
      field: '_musDateCreated',
      headerFilterPlaceholder: 'filter',
      headerFilter: 'input',
      align: 'center',
      width: 100,
    },
    {
      title: 'Updated',
      field: '_musDateUpdated',
      headerFilterPlaceholder: 'filter',
      headerFilter: 'input',
      align: 'center',
      width: 100,
    },
  ];

  const auxFormatData = objData => {
    objData.id = objData.albId;
    objData._musStatus = led(objData.musStatus);
    objData._musFeaturedMain = led(objData.musFeaturedMain, true);
    objData._musFeatured = led(objData.musFeatured, true);
    objData._musAlbum = objData.ALBUM.name;
    objData._musGenre = '';
    objData.GENRE.map((genre) => {
      objData._musGenre += ` | ${genre.name}`;
    });
    objData._musGenre = objData._musGenre.substr(2);
    objData._musArtist = '';
    objData.ARTIST.map((artist) => {
      objData._musArtist += ` | ${artist.name}`;
    });
    objData._musArtist = objData._musArtist.substr(2);
    objData._musDateCreated = format(
      new Date(objData.musDateCreated),
      'MM/dd/yyyy'
    );
    objData._musDateUpdated = format(
      new Date(objData.musDateUpdated),
      'MM/dd/yyyy'
    );
    return objData;
  };

  useEffect(() => {
    async function loadData() {
      const resGetData = await api('GET', '/song', null);
      const {
        data: { resData },
      } = resGetData;

      console.log(resData);
      const tmpData = resData !== null ? resData.map(auxFormatData) : [];
      setData(tmpData);
      setLoading(false);
    }

    try {
      loadData();
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  }, []);

  // propDblClickRedirect="/artist"
  // propDblClickRedirect="undefined"
  const viewData = () => {
    return !isError ? (
      <Table
        propColumns={objColumns}
        propData={lstData}
        // propDblClickRedirect="undefined"
        propDblClickRedirect="/album"
      />
    ) : (
      <Error />
    );
  };

  return (
    <>
      <NavBar />
      <S.Container>
        <S.ContainerHeader>
          <S.ContainerTitle>
            <S.Title>Songs</S.Title>
            {/* <ButtonNew propLink="/song/new" /> */}
          </S.ContainerTitle>
          <Line />
        </S.ContainerHeader>
        <S.ContainerContent>
          {isLoading ? <Loader /> : viewData()}
        </S.ContainerContent>
      </S.Container>
    </>
  );
};

export default Song;
