import styled from 'styled-components';
import media from "styled-query";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8vw;
  height: 100%;
  justify-content: space-between;
`;

export const Type = styled.div`
  display: grid;
  grid-template-rows: 50px auto;
  height: 100%;
  padding-bottom: 50px;
`;

export const Title = styled.h1`
  text-align: start;
  color: #053877;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;

  ${media.lessThan("small")`
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  `}
`;
