import styled from 'styled-components';
import media from 'styled-query';

import { Form as oForm, Input as oInput } from '@rocketseat/unform';

import imgBackground from '../../assets/backgrounds/background-3.png';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  height: 100vh;

  background: white;

  background-image: url(${imgBackground});
  background-size: cover;
`;

export const DivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* text-align: center; */
  align-items: center;
  width: 80vw;
  max-width: 300px;

  justify-content: space-between;

  border-radius: 5px;
  /* border: 0.5px solid #053877; */

  ${media.lessThan('medium')`
    /* screen width is less than 768px (medium) */
    /* background: red; */
    /* width: 70vw;
    height: 80vh; */

  `}

  ${media.between('medium', 'large')`
    /* screen width is between 768px (medium) and 1170px (large) */
    /* background: green; */
    /* width: 50vw;
    height: 50vh; */

    /* form {
      margin-top: 7%;
    } */
  `}

  ${media.greaterThan('large')`
    /* screen width is greater than 1170px (large) */
    /* background: blue; */
    /* width: 50vw;
    height: 50vh;

    form {
      margin-top: 6%;
    } */
  `}
`;

export const Img = styled.img`
  width: 250px;
  height: auto;
  margin-bottom: 10%;
`;

export const DivForm = styled.div`
  /* padding: 25% 15px; */
  width: 100%;
`;

export const Form = styled(oForm)`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* justify-content: space-between; */

  /* flex: 1;
  margin-top: 20%; */

  small {
    color: #00bfff;
    margin-bottom: 5px;
    /* margin-left: 3px; */
    /* margin-bottom: 5px; */
  }
`;

export const DivInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const Input = styled(oInput)`
  background: transparent; //#053877;
  width: 100%;
  max-width: 300px;
  border: 1px solid #00bfff;
  color: #00ffaa;
  border-radius: 5px;

  text-align: center;

  &:disabled {
    background: #05387769;
    color: #00ffaa40;
  }
`;

export const DivButton = styled.div`
  margin-top: 10%;
`;

export const DivButtonSignUp = styled.div`
  margin-top: 20%;

  span {
    color: #00bfff;
    font-size: 15px;
  }

  button {
    margin-top: 10px;
  }
`;
