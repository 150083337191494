import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import {
  NotificationContainer,
  NotificationManager as N,
} from 'react-notifications';

import api from '../../../../services/api';
import { fileUploadImg, fileUploadArtistImg } from '../../../../services/s3';
import ArtistManagerContext from '../../ArtistManagerContext';

import ButtonLoader from '../../../../components/ButtonLoader';
import RatingView from '../../../../components/RatingView';

import 'react-notifications/lib/notifications.css';
import * as S from './styles';

const Image = ({ propData }) => {
  const { viewLoading, viewError, viewContent } = useContext(
    ArtistManagerContext
  );

  const [objDefault, setDefault] = useState({
    artThumbnailSquare: 'https://cdn.artist.pdtvpro.com/artistThumbnailSquare.png',
    artThumbnailBanner: 'https://cdn.artist.pdtvpro.com/artistThumbnailBanner.png',
  });
  const [objData, setData] = useState(propData);
  const [objTemp, setTemp] = useState({
    artThumbnailSquare: objData.artThumbnailSquare,
    artThumbnailBanner: objData.artThumbnailBanner,
  });
  // console.log(objData);

  const optStatus = [
    { id: 'Active', title: 'Active' },
    // { id: 'Processing', title: 'Processing' },
    { id: 'Disabled', title: 'Disabled' },
    // { id: 'Blocked', title: 'Blocked' },
  ];

  const handleCheckFiles = data => {
    console.log('#btn => Images -> Chek File');
    console.log(data);
  };

  const handleChangeFileSquare = async img => {
    console.log('#artThumbnailSquare => Upload -> Start');
    console.log(img);
    try {
      const fileUpload = await fileUploadImg(
        'ARTIST',
        objData.artId,
        'square',
        img[0]
      );
      console.log(fileUpload);

      // Validate Resolutions
      let comImage = document.createElement('img');
      comImage.src = fileUpload.link;
      comImage.onload = function() {
        // console.log(comImage.naturalWidth, comImage.naturalHeight);

        if (comImage.naturalWidth !== 1024 || comImage.naturalHeight !== 1024) {
          N.error('Image', 'Incorrect resolution');
          return false;
        }

        setTemp(prevState => {
          return {
            ...prevState,
            artThumbnailSquare: fileUpload.link,
          };
        });
        console.log('#artThumbnailSquare => Upload -> End');
      }
    } catch (e) {
      console.error(e);
      console.log('#artThumbnailSquare => Upload -> Error');
    }
  };

  const handleChangeFileBanner = async img => {
    console.log('#artThumbnailBanner => Upload -> Start');
    console.log(img);
    try {
      const fileUpload = await fileUploadImg(
        'ARTIST',
        objData.artId,
        'banner',
        img[0]
      );
      console.log(fileUpload);

      // Validate Resolutions
      let comImage = document.createElement('img');
      comImage.src = fileUpload.link;
      comImage.onload = function() {
        // console.log(comImage.naturalWidth, comImage.naturalHeight);

        if (comImage.naturalWidth !== 1920 || comImage.naturalHeight !== 480) {
          N.error('Image', 'Incorrect resolution');
          return false;
        }

        setTemp(prevState => {
          return {
            ...prevState,
            artThumbnailBanner: fileUpload.link,
          };
        });
        console.log('#artThumbnailBanner => Upload -> End');
      }
    } catch (e) {
      console.error(e);
      console.log('#artThumbnailBanner => Upload -> Error');
    }
  };

  const handleSubmit = async data => {
    try {
      // viewLoading();
      const data = {
        artThumbnailSquare: objTemp.artThumbnailSquare,
        artThumbnailBanner: objTemp.artThumbnailBanner,
      };
      console.log(`Data PUT`, data);
      const resPutData = await api(
        'PUT',
        `/artist/${objData.artId}/image`,
        data
      );
      console.log(resPutData);

      N.success('Successfully updated');

      setTimeout(() => {
        // history.push('/');
        // viewContent();
      }, 1000);
    } catch (error) {
      console.error(error);
      N.error('Error updating');
      // viewContent();
    }
  };

  return (
    <>
      <S.Form onSubmit={handleCheckFiles}>
        <S.DivInput propW="20%">
          <small>
            Square <small>1024px x 1024px</small>
          </small>
          <S.LabelInputImage htmlFor="file-square">
            <img
              src={objTemp.artThumbnailSquare}
              alt="Thumbnail Square"
              onError={() =>
                setTemp(prevState => {
                  return {
                    ...prevState,
                    tmpThumbnailSquare: objDefault.artThumbnailSquare,
                  };
                })
              }
            />
            <input
              id="file-square"
              type="file"
              accept="image/*"
              onChange={e => handleChangeFileSquare(e.target.files)}
            />
          </S.LabelInputImage>
        </S.DivInput>
        <S.DivInput propW="80%">
          <small>
            Banner <small>1980px x 480px</small>
          </small>
          <S.LabelInputImage htmlFor="file-banner">
            <img
              src={objTemp.artThumbnailBanner}
              alt="Thumbnail Banner"
              onError={() =>
                setTemp(prevState => {
                  return {
                    ...prevState,
                    tapThumbnailBanner: objDefault.artThumbnailBanner,
                  };
                })
              }
            />
            <input
              id="file-banner"
              type="file"
              accept="image/*"
              onChange={e => handleChangeFileBanner(e.target.files)}
            />
          </S.LabelInputImage>
        </S.DivInput>
      </S.Form>
      <S.Form onSubmit={handleSubmit}>
        <S.DivButton>
          <ButtonLoader propTitle="SAVE" propSpinner={false} />
        </S.DivButton>
      </S.Form>
      <NotificationContainer />
    </>
  );
};

Image.defaultProps = {
  propData: {},
};

Image.propTypes = {
  propData: PropTypes.object,
};

export default Image;
